import Vue from 'vue'
import App from './App.vue'
import router from './router'
import request from './utils/request'
import db from './utils/localstorage'

Vue.config.productionTip = false

Vue.use({
  install(Vue) {
    Vue.prototype.$db = db
  }
})

Vue.prototype.$post = request.post
Vue.prototype.$postJson = request.postJson
Vue.prototype.$get = request.get
Vue.prototype.$put = request.put
Vue.prototype.$putJson = request.putJson

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
