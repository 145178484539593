import Vue from 'vue'
import Router from 'vue-router'
import Auth from '@/views/auth/Auth.vue'

Vue.use(Router)

const constRouter = [
  {
    path: '*',
    redirect: '/'
  },
  {
    path: '/',
    component: Auth
  }
]

const router = new Router({
  routes: constRouter
})

export default router
