<template>
  <div class="auth-page">
    <div>
      <div class="logo">
        <van-image :src="ico_logo" width="90" height="137"/>
      </div>
      <div class="title">特种作业证考试</div>
      <div class="input-area">
        <van-row class="input-row" justify="space-between" align="bottom">
          <van-field v-model="mobile" type="digit" maxlength="11" placeholder="请输入您的手机号" :left-icon="ico_mobile">
            <template #button>
              <van-button class="btn-send-vcode" type="info" :disabled="!enableSendVCode" round @click.stop="handelSendVCode">{{ sendVCodeBtnText }}</van-button>
            </template>
          </van-field>
        </van-row>
        <van-row class="input-row">
          <van-field v-model="vcode" maxlength="6" @input="handleVCodeChanged" placeholder="请输入您收到的验证码" :left-icon="ico_vcode" />
        </van-row>
      </div>
      <div>
        <van-button class="btn-auth" type="info" :disabled="!enableAuth" @click="handleAuth">登录</van-button>
      </div>
    </div>

  </div>
</template>

<script>
import Vue from 'vue';
import moment from 'moment'
import wx from 'wx'
import { Image, Button, Field, Col,Row, Dialog } from 'vant'

Vue.use(Button)
Vue.use(Image)
Vue.use(Field)
Vue.use(Col)
Vue.use(Row)
export default {
  name: 'Auth',
  props: {
  },
  data() {
    return {
      mobile:'',
      vcode:'',
      loading: false,
      lastSendVCodeTime: null,
      interval: null,
      lastSendVCodeDuration: -1,
      ico_mobile: require('@/assets/ico_mobile.png'),
      ico_vcode: require('@/assets/ico_vcode.png'),
      ico_logo: require('@/assets/ico_logo.png'),
    }
  },
  computed: {
    enableSendVCode () {
      if (!this.mobile) return false
      if (!this.isMobile(this.mobile)) return false
      if (this.loading) return false
      if (this.lastSendVCodeDuration >= 0) return false
      return true
    },
    enableAuth () {
      if (!this.mobile) return false
      if (!this.vcode) return false
      if (!this.isMobile(this.mobile)) return false
      if (this.loading) return false
      return true
    },
    sendVCodeBtnText () {
      if (this.lastSendVCodeDuration >= 0) return '获取验证码(' + this.lastSendVCodeDuration + 's)'
      return '获取验证码'
    }
  },
  methods: {
    isMobile (v) {
      return /^1[0-9]{10}$/.test(v)
    },
    computeLastSendVCodeDuration() {
      const limit = 60
      if (this.lastSendVCodeTime) {
        var d = moment().diff(this.lastSendVCodeTime, 'seconds')
        if (d <= limit) return limit-d
      }
      return -1
    },
    handleVCodeChanged(v) {
      if (v && v.length > 6) {
        v = v.slice(0,6)
      }
      this.vcode = v
    },
    handelSendVCode() {
      if (!this.enableSendVCode) return

      this.loading = true
      const params = { mobile: this.mobile }
      this.$postJson('/yks/auth/vcode/send', params).then((r) => {
        this.loading = false
        this.lastSendVCodeTime = moment()
        this.$db.save('AUTH-SEND-VCODE-TIME', {time:this.lastSendVCodeTime})
        this.lastSendVCodeDuration = this.computeLastSendVCodeDuration()

        const res = r.data
        // console.log(res)
        if (res.code === 0) {
          // console.log('success')
        } else {
          Dialog.alert({
              title: '失败',
              message: res.message,
              confirmButtonText: '重新尝试',
              overlay: true
          }).then(() => {
          })
        }
      }).catch(()=> {
        this.loading = false
        Dialog.alert({
            title: '失败',
            message: '发送验证码失败',
            confirmButtonText: '重新尝试',
            overlay: true
        }).then(() => {
        })
      })
    },
    handleAuth() {
      if (this.loading) return

      this.loading = true
      const params = { mobile: this.mobile, vcode: this.vcode }
      this.$postJson('/yks/auth/vcode/check', params).then((r) => {
        setTimeout(() => {
          this.loading = false
        }, 500)
        const res = r.data
        // console.log(res)
        if (res.code === 0) {
          const data  = res.data || data
          if (data) {
            // console.log(location.href)
            this.mobile = ''
            this.vcode  = ''
            location.href = data
          }
        } else {
          Dialog.alert({
              title: '失败',
              message: res.message,
              confirmButtonText: '重新尝试',
              overlay: true
          }).then(() => {
          })
        }
      }).catch(()=> {
        setTimeout(() => {
          this.loading = false
          Dialog.alert({
              title: '登录失败',
              message: '登录失败，请检查所填手机及验证码',
              confirmButtonText: '重新尝试',
              overlay: true
          }).then(() => {
          })
        }, 500)
      })
    },
    requestConfig() {
      var that = this
      var url = location.href.split('#')[0]
      var param = {url : url}
      this.$postJson('/yks/auth/config', param).then((r) => {
        const res = r.data
        // console.log(res)
        if (res.code === 0) {
          if (!wx) {
            // Dialog.alert({ title: '分享', message: '!wx', overlay: true }).then(() => { })
            return
          }
          const data = res.data || {}
          wx.config({
            beta: true,
            debug: false,
            appId: data.appId,
            timestamp: data.timestamp,
            nonceStr: data.nonceStr,
            signature: data.signature,
            jsApiList: ['onMenuShareAppMessage', 'onMenuShareTimeline']
          })
          const shareTitle = '美保特种作业证考试'
          const shareUrl = 'https://portal.kaoshi.meibaokeji.com/'
          const shareImg = 'https://portal.kaoshi.meibaokeji.com/logo.png'
          const shareDesc = '快来一起学习吧！'
          wx.ready(function () {
            // Dialog.alert({ title: '分享', message: 'wx.ready', overlay: true }).then(() => { })
            wx.onMenuShareAppMessage({
              title: shareTitle,
              desc: shareDesc,
              link: shareUrl,
              imgUrl : shareImg,
              type: 'link',
              success: function (res) {
                // Dialog.alert({ title: '分享', message: 'onMenuShareAppMessage success', overlay: true }).then(() => { })
              },
              fail: function (res) {
                // Dialog.alert({ title: '分享', message: 'onMenuShareAppMessage fail', overlay: true }).then(() => { })
              }
            })
            wx.onMenuShareTimeline({
              title : shareTitle,
              link : shareUrl,
              imgUrl : shareImg,
              success : function() {
                // Dialog.alert({ title: '分享', message: 'onMenuShareTimeline success', overlay: true }).then(() => { })
              },
              cancel : function() {
                // Dialog.alert({ title: '分享', message: 'onMenuShareTimeline fail', overlay: true }).then(() => { })
              }
            })
          })

          wx.error(function (res) {
            that.initWeworkFail(res)
          })

        }
      }).catch(()=> {
      })
    },
  },
  mounted() {
    this.mobile = ''
    this.vcode  = ''
    var v = this.$db.get('AUTH-SEND-VCODE-TIME', null)
    this.lastSendVCodeTime = (v && v.time) ? moment(v.time) : null
    if (this.lastSendVCodeTime < moment(1)) this.lastSendVCodeTime = null
    this.lastSendVCodeDuration = this.computeLastSendVCodeDuration()
    this.interval = setInterval(() => {
      this.lastSendVCodeDuration = this.computeLastSendVCodeDuration()
    }, 1000)
    this.loading = false
    this.requestConfig()
    
  },
  beforeDestroy () {
    if (this.interval) {
      clearInterval(this.interval)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.auth-page {
  margin-top: 60px;
  padding: 16px 16px;
}
.logo {
  margin-top: 20px;
}
.title {
  font-size: 18px;
  font-weight: 500;
  color: #333333;
  line-height: 25px;
  margin-top:30px;
}
.field-send-vcode {
  width: 200px;
}
.btn-send-vcode {
  width: 130px;
  height: 32px;
  font-size: 13px;
  box-shadow: 0px 0px 6px 0px rgba(50, 150, 255, 0.5);
}
.input-area {
  margin-top:30px;
}
.input-row {
  margin-bottom: 12px;
  box-shadow: 0px 1px 0px 0px #F0F0F0;
  height: 44px;
  border: 0px 0px 1px 0px;
  font-size: 15px;
  line-height: 21px;
}
.van-icon {
  width: 16px;
  height: 20px;
}
.btn-auth {
  width: 100%;
  height: 44px;
  font-size: 18px;
  
  box-shadow: 0px 5px 15px 0px rgba(10, 123, 241, 0.2);
  border-radius: 5px;
  margin-top: 48px;
}

</style>
