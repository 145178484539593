import axios from 'axios'

//console.log(location.href)
const httpProtocol = location.href.startsWith('https://') ? 'https://' : 'http://'
const httpRequest = axios.create({
  baseURL: httpProtocol + process.env.VUE_APP_API_BASE_URL,
  responseType: 'json',
  validateStatus(status) {
    // 200 外的状态码都认定为失败
    return status === 200
  }
})

const request = {
  postJson(url, params) {
    return httpRequest.post(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  post(url, params) {
    return httpRequest.post(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  put(url, params) {
    return httpRequest.put(url, params, {
      transformRequest: [(params) => {
        let result = ''
        Object.keys(params).forEach((key) => {
          if (!Object.is(params[key], undefined) && !Object.is(params[key], null)) {
            result += encodeURIComponent(key) + '=' + encodeURIComponent(params[key]) + '&'
          }
        })
        return result
      }],
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
  },
  putJson(url, params) {
    return httpRequest.put(url, params, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
  },
  get(url, params) {
    let _params
    if (Object.is(params, undefined)) {
      _params = ''
    } else {
      _params = '?'
      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key) && params[key] !== null) {
          _params += `${key}=${params[key]}&`
        }
      }
    }
    return httpRequest.get(`${url}${_params}`)
  }
}

export default request
